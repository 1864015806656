import React from 'react';

import {ComplianceStatus, UserStatus} from '../../generated';

type StatusProps = {
  status?: ComplianceStatus | UserStatus;
};

export default function StatusDot({status}: StatusProps) {
  const statusColor = {
    [ComplianceStatus.AwaitingSubmission]: 'bg-wallGrey',
    [ComplianceStatus.Compliant]: 'bg-green-500',
    [ComplianceStatus.NonCompliant]: 'bg-red-500',
    [ComplianceStatus.Pending]: 'bg-yellow-500',
    [UserStatus.Active]: 'bg-green-500',
    [UserStatus.Created]: 'bg-orange',
    [UserStatus.Deactivated]: 'bg-red-500',
    default: 'bg-gray-400',
  };

  return (
    <div
      className={`w-[12px] h-[12px] rounded-full ${
        status ? statusColor[status] : statusColor.default
      }`}
    />
  );
}
