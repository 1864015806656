import {Button} from '@cashiaApp/web-components';

import {ReactComponent as Plus} from '../../assets/icons/add-square.svg';

type HeaderStatsProps = {
  action?: {
    label: string;
    onClick: () => void;
  };
  className?: string;
};

export const HeaderStats = ({action, className = ''}: HeaderStatsProps) => {
  return (
    <div className={`flex justify-between items-center py-6 ${className}`}>
      <div className="flex gap-6" />
      {action && (
        <Button
          onClick={action.onClick}
          className="bg-surfacePurple hover:bg-mediumPurple">
          <Plus className="w-4 h-4 mr-2" />
          {action.label}
        </Button>
      )}
    </div>
  );
};
