import {Button, Label} from '@cashiaApp/web-components';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {ReactComponent as FacebookIcon} from '../../assets/icons/facebook.svg';
import {ReactComponent as ImagePlaceHolderIcon} from '../../assets/icons/img-placeholder.svg';
import {ReactComponent as InstagramIcon} from '../../assets/icons/instagram.svg';
import {ReactComponent as TiktokIcon} from '../../assets/icons/tiktok.svg';
import CustomInput from '../../components/common/CustomInput';
import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import CustomSpinner from '../../components/common/CustomSpinner';
import EditButton from '../../components/common/EditButton';
import Navbar from '../../components/common/Navbar';
import StatusDot from '../../components/common/StatusDot';
import {
  ComplianceStatus,
  useGetBusinessQuery,
  VerificationIdType,
} from '../../generated';
import userInitials from '../../utils/getNameInitials';
import {getFileNameFromUrl} from '../../utils/helpers';
import formatDate from '../../utils/reusablefunctions/formatDate';

const breadcrumbs = [
  {
    name: 'Business Profiles',
    location: '/business-profiles',
  },
  {
    name: 'Business Profile Details',
  },
];
const complianceStatus = {
  [ComplianceStatus.AwaitingSubmission]: 'Awaiting submission',
  [ComplianceStatus.Compliant]: 'Compliant',
  [ComplianceStatus.Pending]: 'Pending',
  [ComplianceStatus.NonCompliant]: 'Non Compliant',
};

const idTypes = {
  [VerificationIdType.AlienCard]: 'Alien',
  [VerificationIdType.NationalId]: 'National ID',
  [VerificationIdType.Passport]: 'Passport',
};
export default function ProfileDetails() {
  const {id} = useParams<{id: string}>();

  const {data: businessData, loading} = useGetBusinessQuery({
    variables: {
      id: id,
    },
    skip: !id,
  });

  const business = useMemo(() => businessData?.business, [businessData]);

  const initials = useMemo(
    () => (business ? userInitials(business?.name || '') : ''),
    [business]
  );

  const userName = useMemo(
    () =>
      `${businessData?.business?.members?.[0]?.firstName || ''} ${businessData?.business?.members?.[0]?.lastName || ''}`,
    [businessData]
  );

  return (
    <div>
      <Navbar
        title="Business Profile Details"
        omitSearch
        breadcrumbs={breadcrumbs}
      />
      {loading ? (
        <CustomSpinner />
      ) : (
        <div>
          <div className="m-6 border border-dividerGrey p-8  rounded-lg h-[136px] flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="relative inline-flex h-[56px] w-[56px] items-center justify-center rounded-full bg-mediumPurple text-xl font-medium text-white">
                {initials}
              </div>
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                {business?.name}
              </h1>
            </div>
            <div className="flex gap-10 items-center">
              <div className="flex gap-2 items-center">
                <StatusDot status={business?.complianceStatus} />
                <div>
                  <h1 className="text-foggy font-semibold text-xs">
                    Compliance Status
                  </h1>
                  {business && (
                    <h1 className="font-semibold text-base">
                      {complianceStatus[business.complianceStatus]}
                    </h1>
                  )}
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <h1 className="text-foggy font-semibold text-xs">
                    Account Name
                  </h1>
                  <h1 className="font-semibold text-base">{userName}</h1>
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <h1 className="text-foggy font-semibold text-xs">
                    Created Date
                  </h1>
                  <h1 className="font-semibold text-base">
                    {formatDate(new Date(business?.createdAt as string), {
                      withTime: true,
                      withNumericDate: true,
                    })}
                  </h1>
                </div>
              </div>

              <Button className="w-[235px] h-[44px] font-semibold  text-white text-sm bg-surfacePurple hover:bg-mediumPurple border rounded-lg">
                Upload Compliance Status
              </Button>
            </div>
          </div>

          <div className="m-6 border border-dividerGrey p-8  rounded-lg h-auto">
            <div className="mb-2 flex justify-between">
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                Business information
              </h1>
              <EditButton />
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-10">
                <CustomInput label="Business Name" value={business?.name} />
                <CustomInput
                  label="Business Category"
                  value={business?.category?.name}
                />
              </div>
              <div className="flex items-center gap-10">
                <div className="flex flex-col">
                  <Label
                    className={`
                  sm:font-medium md:font-medium text-grey  sm:text-[15px] md:text-[16px] mb-3`}>
                    Business Phone Number
                  </Label>
                  <CustomPhoneInput
                    countryCode={business?.phoneNumber?.countryCode || ''}
                    phoneNumber={business?.phoneNumber?.number || ''}
                    isDisabled
                  />
                </div>
                <CustomInput
                  label="Business Address"
                  value={business?.address?.location.formattedAddress}
                />
              </div>
              <div className="flex items-center gap-10">
                <CustomInput
                  label="Business Description"
                  value={business?.description}
                />
                <CustomInput
                  label="Complex/ Building Name"
                  value={business?.address?.buildingName}
                />
              </div>
            </div>
          </div>

          <div className="m-6 border border-dividerGrey p-8  rounded-lg h-auto">
            <div className="mb-2 flex justify-between">
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                Business Owner information
              </h1>
              <EditButton />
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-10">
                <CustomInput
                  label="First Name"
                  value={business?.owner?.firstName}
                />
                <CustomInput
                  label="Last Name"
                  value={business?.owner?.lastName}
                />
              </div>
              <div className="flex items-center gap-10">
                <CustomInput
                  label="Identification Document Type"
                  value={
                    idTypes[
                      business?.owner?.idType || VerificationIdType.NationalId
                    ]
                  }
                />
                <div>
                  <Label
                    className={`
              sm:font-medium md:font-medium text-grey  sm:text-[15px] md:text-[16px]`}>
                    Copy of Valid Proof of Identity
                  </Label>
                  <a href={business?.owner.idPhotos?.[0]?.url} target="blank">
                    <div className="flex items-center gap-2 px-2 border-dashed border-borderColor border-[1px] w-[480px] h-[48px] bg-disabledGrey rounded-lg mt-1">
                      <ImagePlaceHolderIcon height={30} />
                      <p>
                        {getFileNameFromUrl(
                          business?.owner.idPhotos?.[0]?.url || ''
                        )}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="flex items-center gap-10">
                <CustomInput
                  label="Residential Address"
                  value={business?.owner?.address.location.formattedAddress}
                />
                <CustomInput
                  label="Complex/ Building Name"
                  value={business?.owner?.address.buildingName}
                />
              </div>
            </div>
          </div>

          <div className="m-6 border border-dividerGrey p-8 rounded-lg h-auto">
            <div className="mb-2 flex justify-between">
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                Bank Account Details
              </h1>
              <EditButton />
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-10">
                <CustomInput
                  label="Bank Name"
                  value={business?.bankAccount?.bank.name}
                />
                <CustomInput
                  label="Account Holder Name"
                  value={business?.bankAccount?.accountHolder}
                />
              </div>
              <div className="flex items-center">
                <CustomInput
                  label="Account Number"
                  value={business?.bankAccount?.accountNumber}
                />
              </div>
            </div>
          </div>

          <div className="m-6 border border-dividerGrey p-8  rounded-lg h-auto">
            <div className="mb-2 flex justify-between">
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                Social Media
              </h1>
              <EditButton />
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-10">
                <CustomInput
                  label="Website"
                  value={business?.socialMedia.website}
                />
                <div>
                  <Label
                    className={`
              sm:font-medium md:font-medium text-grey  sm:text-[15px] md:text-[16px]`}>
                    Socials
                  </Label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <FacebookIcon />
                    </div>
                    <CustomInput
                      inputClassName="pl-[50px]"
                      label=""
                      value={business?.socialMedia.facebook}
                    />
                  </div>
                  <div className="relative mt-3 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <InstagramIcon />
                    </div>
                    <CustomInput
                      inputClassName="pl-[50px]"
                      label=""
                      value={business?.socialMedia.instagram}
                    />
                  </div>
                  <div className="relative mt-3 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <TiktokIcon />
                    </div>
                    <CustomInput
                      inputClassName="pl-[50px]"
                      label=""
                      value={business?.socialMedia.facebook}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
