import {Button} from '@cashiaApp/web-components';
import React from 'react';

import editIcon from '../../assets/icons/edit-icon.svg';

type Props = {
  onButtonClick?: () => void;
};

const EditButton = ({onButtonClick}: Props) => {
  return (
    <div>
      <Button
        className="w-[85px] h-[44px] border-dividerGrey font-semibold border text-base hover:shadow-sm hover:shadow-surfacePurple hover:border-lightPurple rounded-lg bg-faintGrey text-fontGrey"
        onClick={onButtonClick}>
        <img src={editIcon} alt="edit" className="mr-2" />
        Edit
      </Button>
    </div>
  );
};

export default EditButton;
