import {useEffect, useState} from 'react';

export const daysOfWeek = {
  mo: 'Monday',
  tu: 'Tuesday',
  we: 'Wednesday',
  th: 'Thursday',
  fr: 'Friday',
  sa: 'Saturday',
  su: 'Sunday',
} as const;

export type DayType = {
  title: string;
  display: string;
  key: string;
};

export type OperatingDayTime = {
  dayKey?: string;
  startTime?: string;
  endTime?: string;
};

export type CardItem = {
  openingHours?: string;
  closingHours?: string;
  days: Array<DayType>;
};

export type Duration = {
  from?: string;
  to?: string;
};

export type OperatingTime = {
  [key: string]: Duration;
};

export type DayOfWeek = keyof typeof daysOfWeek;

export const days = [
  {
    title: 'Monday',
    display: 'Mon',
    key: 'mo',
  },
  {
    title: 'Tuesday',
    display: 'Tue',
    key: 'tu',
  },
  {
    title: 'Wednesday',
    display: 'Wen',
    key: 'we',
  },
  {
    title: 'Thursday',
    display: 'Thur',
    key: 'th',
  },
  {
    title: 'Friday',
    display: 'Fri',
    key: 'fr',
  },
  {
    title: 'Saturday',
    display: 'Sat',
    key: 'sa',
  },
  {
    title: 'Sunday',
    display: 'Sun',
    key: 'su',
  },
];
export default function useDebounce(value: string, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function isArrayAtKey(
  obj: Record<string, unknown>,
  key: string
): boolean {
  return Array.isArray(obj[key]);
}

export function getFileNameFromUrl(url: string): string {
  const parts = url.split('/');
  return parts[parts.length - 1] || '';
}
