import {useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

import Logo from '../../assets/icons/cashia_logo.svg';
import Spinner from '../../components/common/Spinner';
import {useAuth} from '../../utils/auth';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassord] = useState('');
  const {makeLoginWithPost, loginError, isAuthenticated} = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setEmail('');
    setPassord('');
    setLoading(true);
    await makeLoginWithPost(email, password);
    setLoading(false);
  };

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    if (!loginError) return;
    toast.error(loginError, {position: 'bottom-left'});
  }, [loginError]);

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate('/accounts');
  });

  return (
    <div className="flex min-h-screen flex-1 flex-col">
      <div className="flex flex-1 flex-col px-[100px] pt-[50px]">
        <div className="relative">
          <img className="absolute left-0 top-0" src={Logo} />
        </div>
        <div className="my-auto flex h-fit flex-1 basis-full flex-col items-center justify-center">
          <div className="flex w-[600px]  flex-col">
            <h1 className="mb-[50px] text-center font-[Metropolis-Bold] text-[50px] font-[800]">
              Log in
            </h1>
            <div className="flex flex-col">
              <div className="mb-4">
                <div className="relative flex">
                  <input
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    id="email"
                    aria-describedby="email"
                    className="peer flex basis-full appearance-none rounded-lg border-half border-greyish bg-offWhite px-2.5 pb-2 pt-4 text-sm text-black autofill:!bg-offWhite focus:!bg-white focus:outline-none focus:ring-2  focus:ring-black"
                    placeholder=" "
                  />
                  <label
                    htmlFor="email"
                    className="text-m absolute left-2.5 top-3 z-10 origin-[0] -translate-y-3 scale-75 transform text-wallGrey duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75">
                    Email
                  </label>
                </div>
                <p
                  id="email_help"
                  className="mb-2 hidden pl-2 text-xs text-green-600 dark:text-green-400">
                  <span className="font-medium">Well done!</span> Some success
                  message.
                </p>
              </div>
              <div className="mb-4">
                <div className="relative flex">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassord(e.currentTarget.value)}
                    aria-describedby="passowrd"
                    className="peer flex basis-full appearance-none rounded-lg border-half border-greyish bg-offWhite px-2.5 pb-2 pt-4 text-sm text-black focus:!bg-white focus:outline-none focus:ring-2  focus:ring-black"
                    placeholder=" "
                  />
                  <label
                    htmlFor="password"
                    className="text-m absolute left-2.5 top-3 z-10 origin-[0] -translate-y-3 scale-75 transform text-wallGrey duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75">
                    Password
                  </label>
                  <p
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-4 cursor-pointer text-xs text-trueBlack underline ">
                    {showPassword ? 'hide' : 'show'}
                  </p>
                </div>
                <p
                  id="password_help"
                  className="mb-2 hidden pl-2 text-xs text-green-600 dark:text-green-400">
                  <span className="font-medium">Well done!</span> Some success
                  message.
                </p>
              </div>
              <button
                disabled={!email || !password}
                onClick={handleLogin}
                className="flex h-[48px] basis-full items-center justify-center rounded-lg bg-mediumPurple py-2.5 text-white disabled:bg-greyish">
                {loading ? <Spinner /> : 'Login'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <footer className="border-t-black-100 flex h-16 items-center justify-between border-t-[1px] px-[100px]">
        <p>&copy; {currentYear} Cashia</p>
        <p className="text-red-600">Terms of Use</p>
      </footer>
    </div>
  );
};

export default Login;
