interface Options {
  withWeekday?: boolean;
  withTime?: boolean;
  withDate?: boolean;
  withMonth?: boolean;
  withShortMonth?: boolean;
  withYear?: boolean;
  hour12?: boolean;
  locales?: string | string[];
  withNumericDate?: boolean;
  with24Hour?: boolean;
  withSeconds?: boolean;
}

type FormatDate = (date: Date, options?: Options) => string;

const formatDate: FormatDate = (
  date,
  options = {
    withDate: true,
    withTime: true,
    withShortMonth: false,
    withYear: true,
    hour12: true,
    locales: 'en-KE',
    withNumericDate: false,
    with24Hour: false,
    withSeconds: false,
  }
) => {
  if (!date) {
    return date;
  }
  const d = new Date(date);

  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = String(d.getFullYear()).slice(-2);

  const time = d
    .toLocaleString(options.locales, {
      hour: 'numeric',
      minute: 'numeric',
      second: options.withSeconds ? 'numeric' : undefined,
      hour12: !options.with24Hour,
    })
    .toUpperCase();

  if (options.withNumericDate) {
    if (options.with24Hour && options.withSeconds) {
      return `${day}/${month}/${year}, ${time}`;
    } else {
      return `${day}/${month}/${year} - ${time}`;
    }
  }
  const formattedMonth = options.withShortMonth
    ? d.toLocaleString(options.locales, {month: 'short'})
    : d.toLocaleString(options.locales, {month: 'long'});

  const longDate = `${day} ${formattedMonth}, ${year}`;
  return options.withTime ? `${longDate} - ${time}` : longDate;
};

export default formatDate;
