import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import CustomSpinner from '../../components/common/CustomSpinner';
import EmptyState from '../../components/common/EmptyState';
import Navbar from '../../components/common/Navbar';
import Paginator from '../../components/common/Paginator';
import CommonTable from '../../components/common/table';
import {useGetBusinessesQuery} from '../../generated';
import formatDate from '../../utils/reusablefunctions/formatDate';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';

const columns: TableColumnType[] = [
  'business_name',
  'category',
  'owner_name',
  'phone_number',
  'date_created',
  'compliance_status',
];
const BusinessProfiles = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(1);
  const [currentCursor, setCurrentCursor] = useState<string | null>(null);
  const [cursorStack, setCursorStack] = useState<string[]>([]);
  const PAGE_SIZE = 16;

  const {data, loading} = useGetBusinessesQuery({
    variables: {
      input: {
        cursor: {
          first: PAGE_SIZE,
          after: currentCursor,
        },
      },
    },
  });

  const transformedData = React.useMemo(() => {
    if (!data?.businesses.edges) return [];

    return data.businesses.edges.map(({node}) => ({
      id: node.id,
      business_name: [node.name, ''],
      category: node.category?.name,
      owner_name: node.members[0]
        ? `${node.members[0].firstName} ${node.members[0].lastName}`
        : '',
      phone_number: node.phoneNumber
        ? `${node.phoneNumber.countryCode}${node.phoneNumber.number}`
        : '',
      date_created: node.createdAt
        ? formatDate(new Date(node.createdAt as string), {
            withTime: true,
            withNumericDate: true,
          })
        : '---',
      compliance_status: [node.complianceStatus, '', ''],
    })) as TableDataType[];
  }, [data]);

  const loadMore = useCallback(
    async (next: boolean) => {
      try {
        if (next && data?.businesses.pageInfo.hasNextPage) {
          const nextCursor = data.businesses.pageInfo.endCursor;
          if (nextCursor) {
            if (currentCursor) {
              setCursorStack((prev) => [...prev, currentCursor]);
            }
            setCurrentCursor(nextCursor);
            setCount((prev) => prev + 1);
          }
        } else if (!next && count > 1) {
          if (cursorStack.length > 0) {
            const previousCursor = cursorStack[cursorStack.length - 1];
            if (previousCursor) {
              setCursorStack((prev) => prev.slice(0, -1));
              setCurrentCursor(previousCursor);
              setCount((prev) => prev - 1);
            }
          } else {
            setCurrentCursor(null);
            setCount(1);
          }
        }
      } catch (error) {
        console.error('Error loading more businesses:', error);
      }
    },
    [data, currentCursor, cursorStack, count]
  );

  const handleViewDetails = (id: string) => {
    navigate(`/business-profiles/details/${id}`);
  };

  return (
    <>
      <Navbar omitSearch placeholder="" title="Business Profiles" />
      <div className="px-6 py-4 h-full overflow-y-auto">
        {loading && !data ? (
          <CustomSpinner />
        ) : data?.businesses.edges.length ? (
          <>
            <div className="flex flex-col h-full">
              <h2 className="text-2xl px-2 font-semibold">
                Business Profile List
              </h2>

              <div className="bg-white rounded-lg flex-1">
                <CommonTable
                  columns={columns}
                  data={transformedData}
                  prettify
                  viewRecord={handleViewDetails}
                />
              </div>

              {data.businesses.pageInfo.entries > PAGE_SIZE && (
                <div className="mt-auto h-[60px] p-6">
                  <Paginator
                    count={count}
                    pageInfo={{
                      ...data.businesses.pageInfo,
                      hasPrevPage: count > 1,
                    }}
                    loadMore={loadMore}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <EmptyState
            title="It's lonely out here"
            description="All business profiles will be displayed here"
          />
        )}
      </div>
    </>
  );
};

export default BusinessProfiles;
