const specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
const digits = /^\d+$/;
const spaces = /\s/;
const alphabet = /^[a-zA-Z\s]+$/;
const emailRegex =
  /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;

export const hasSpecialChars = (value: string) =>
  specialChars.test(value.trim());

export const hasDigits = (value: string) => digits.test(value.trim());

export const isAlphabetOnly = (value: string) => alphabet.test(value.trim());

export const isValidEmail = (value: string) => emailRegex.test(value.trim());

export const hasSpaces = (value: string) => spaces.test(value.trim());

export const isTooShort = (value: string, minLength: number) =>
  value.trim().length < minLength;

export const isOutOfRange = (value: string, min: number, max: number) =>
  Number(value.trim().replace(/,/g, '')) < min ||
  Number(value.trim().replace(/,/g, '')) > max;

export const allValuesNonEmpty = (obj: {[s: string]: unknown}) => {
  return Object.values(obj).every(
    (value) => value !== null && value !== undefined && value !== ''
  );
};

export const validateKenyanPhoneNumber = (phoneNumber: string) => {
  if (!/^\d+$/.test(phoneNumber)) return false;
  const cleanedNumber = phoneNumber.replace(/\D/g, '');
  const kenyanPhoneRegex = /^(?:\|0)?([17]\d{8})$/;
  const match = cleanedNumber.match(kenyanPhoneRegex);

  return match;
};
