import React from 'react';

import {ReactComponent as Info} from '../../assets/icons/info-circle.svg';

interface RenderErrorMessageProps {
  error?: string;
}

const RenderErrorMessage: React.FC<RenderErrorMessageProps> = ({error}) => {
  if (!error) return null;

  return (
    <div className="flex mt-2">
      <Info className="pr-1" />
      <p className="text-orangeDark pl-1 pr-1 rounded text-sm font-normal bg-lightOrange">
        {error}
      </p>
    </div>
  );
};

export default RenderErrorMessage;
