import {Input, Label} from '@cashiaApp/web-components';

type InputInfo = {
  label?: string;
  value?: string | null;
  placeholder?: string;
  labelClassName?: string;
  inputClassName?: string;
};

const CustomInput = ({
  label,
  value,
  placeholder,
  labelClassName,
  inputClassName,
}: InputInfo) => {
  return (
    <div className="flex flex-col gap-2 sm:w-full md:w-[480px]">
      {label && (
        <Label
          id={label}
          className={`
       sm:font-medium md:font-medium text-grey  sm:text-[15px] md:text-[16px]
          ${labelClassName || ''}`}>
          {label}
        </Label>
      )}
      <Input
        className={`
          disabled:cursor-default disabled:opacity-100 text-textGrey sm:text-[15px] md:text-[16px] font-medium h-[48px]
             ${inputClassName || ''}`}
        value={value || ''}
        id={label}
        placeholder={placeholder}
        disabled
      />
    </div>
  );
};

export default CustomInput;
