import React, {useContext, useState, useEffect, PropsWithChildren} from 'react';
import {toast} from 'react-hot-toast';

import {isUser} from './apollo/helpers';
import {useAuth} from './auth';
import {useMeQuery, UserFieldsFragment} from '../generated';

interface Context {
  user?: UserFieldsFragment;
  admin?: UserFieldsFragment;
}

export const UserContext = React.createContext<Context>({});

export const useUserAuth = (): Context => useContext(UserContext);

const UserProvider = ({children}: PropsWithChildren) => {
  const [user, setUser] = useState<UserFieldsFragment>();
  const {isAuthenticated} = useAuth();
  const {data, error} = useMeQuery({skip: !isAuthenticated});

  useEffect(() => {
    if (!!data?.me && isUser(data?.me) && !error) {
      setUser(data?.me || undefined);
    }
  }, [data, error]);

  useEffect(() => {
    if (!error || !isAuthenticated) return;
    toast.error(error.message, {
      duration: 4000,
      position: 'bottom-left',
    });
  }, [error, isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        user,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
