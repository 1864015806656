import React from 'react';

import {ReactComponent as Info} from '../../assets/icons/info-circle.svg';

interface RenderErrorMessageProps {
  error?: string;
}

const RenderErrorMessage: React.FC<RenderErrorMessageProps> = ({error}) => {
  if (!error) return null;

  return (
    <div className="mt-2">
      <div className="inline-flex items-center border border-pinkRed bg-pinkRed p-1 rounded-md">
        <Info className="pr-1" />
        <p className="text-errorRed pl-1 pr-1 text-sm font-normal">{error}</p>
      </div>
    </div>
  );
};

export default RenderErrorMessage;
