import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {createLink} from 'apollo-absinthe-upload-link';
import React, {PropsWithChildren} from 'react';

import typePolicies from './apollo/typePolicies';
import {useAuth, promiseToObservable} from './auth';
import {localStorageAccessTokenKey, apiUrl} from './constants';
import {ErrorTypes} from '../generated';
import result from '../introspection-result';
import resolvers from './mocks/resolvers';
import typeDefs from './mocks/typeDefs';

interface Props {
  terminatingLink?: ApolloLink;
}

const httpLink = createLink({uri: apiUrl});

const GraphQLProvider = ({
  children,
  terminatingLink = httpLink,
}: PropsWithChildren<Props>) => {
  const {getNewTokens, logout} = useAuth();

  const authMiddleware = setContext(() => {
    const token = localStorage.getItem(localStorageAccessTokenKey);
    return {
      headers: {
        ...(token ? {authorization: `Bearer ${token}`} : {}),
      },
    };
  });

  const errorLink = onError(({graphQLErrors, operation, forward}) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const errorType = err.extensions?.['type'] as ErrorTypes;
        switch (errorType) {
          case ErrorTypes.TokenExpired:
            return promiseToObservable(getNewTokens()).flatMap(() =>
              forward(operation)
            );
          case ErrorTypes.TokenInvalid:
            void logout();
            break;
        }
      }
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: result.possibleTypes,
      typePolicies,
    }),
    link: errorLink.concat(authMiddleware).concat(terminatingLink),
    resolvers,
    typeDefs,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
