import React from 'react';
import {useNavigate} from 'react-router-dom';

import forwardArrow from '../../assets/icons/forwardArrow.svg';

export type BreadcrumbType = {
  name: string | '';
  location?: string;
};

type Props = {
  breadcrumbs: BreadcrumbType[];
};

const Breadcrumb = ({breadcrumbs}: Props) => {
  const navigate = useNavigate();

  return (
    <ol className="flex items-center gap-3" aria-label="Breadcrumb">
      {breadcrumbs.map((item, i) => (
        <li key={i} className="flex cursor-pointer items-center gap-3">
          {i !== 0 && <img src={forwardArrow} />}
          <p
            onClick={() => item.location && navigate(item.location)}
            className={`text-base	font-medium	leading-4 text-foggy  ${
              i === breadcrumbs.length - 1
                ? ' font-semibold text-[#3A3441] decoration-grey '
                : 'hover:text-surfacePurple text-[#B2ABBF]'
            }`}>
            {item.name}
          </p>
        </li>
      ))}
    </ol>
  );
};

export default Breadcrumb;
