import {Input} from '@cashiaApp/web-components';
import React, {useMemo} from 'react';
import Select, {StylesConfig} from 'react-select';

import {cn} from '../../utils/reusablefunctions';

interface CountryOption {
  label: string;
  value: string;
  flag: string;
}

interface CustomPhoneInputProps {
  countryCode: string;
  phoneNumber: string;
  onChange?: (countryCode: string, number: string) => void;

  error?: JSX.Element | null;
  isDisabled?: boolean;
}

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = ({
  countryCode,
  phoneNumber,
  onChange,
  error,
  isDisabled = false,
}) => {
  const options = useMemo<CountryOption[]>(
    () => [
      {
        label: '+254',
        value: 'KE',
        flag: 'https://flagcdn.com/24x18/ke.png',
      },
    ],
    []
  );

  const selectedCountry =
    options.find((option) => option.label === countryCode) || options[0];

  const handleCountryChange = (option: CountryOption | null) => {
    if (option && onChange) {
      onChange(option.label, phoneNumber || '');
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = e.target.value.replace(/\D/g, '');
    if (onChange) {
      onChange(`${countryCode ?? options[0]?.label ?? ''}`, numberValue);
    }
  };

  const customStyles: StylesConfig<CountryOption, false> = {
    control: (provided, state) => ({
      ...provided,
      width: '100px',
      color: '#7F738E',
      fontWeight: '500',
      fontSize: '16px',
      height: '48px',
      borderRadius: '8px',
      backgroundColor: isDisabled ? '#F0F0F3' : undefined,
      borderColor: isDisabled ? '#D6D4DE' : '#D5D3D1',
      boxShadow: state.isFocused ? '0 0 0 1px #FECED8' : 'none',
      '&:hover': {
        borderColor: '#FECED8',
      },
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  const formatOptionLabel = ({label, flag}: CountryOption) => (
    <div className="flex items-center">
      <img src={flag} alt="flag" className="mr-2" />
      <p
        className={cn('text-[15px]', {
          'text-textGrey font-medium': isDisabled,
        })}>
        {label}
      </p>
    </div>
  );

  return (
    <div>
      <div className="flex gap-2">
        <Select<CountryOption>
          options={options}
          value={selectedCountry}
          onChange={handleCountryChange}
          isDisabled={isDisabled}
          styles={customStyles}
          formatOptionLabel={formatOptionLabel}
          placeholder="Select country"
        />
        <Input
          type="tel"
          name="phoneNumber"
          value={phoneNumber || ''}
          onChange={handlePhoneNumberChange}
          className={cn(
            `bg-transparent rounded-lg h-[46px] max-md:w-[60vw] w-[380px] focus-visible:border-none focus:text-surfacePurple text-base  focus-visible:ring-1 focus-visible:ring-mediumPurple`,
            {
              'bg-faintGrey font-medium rounded-lg h-[46px] max-md:w-[60vw] text-fontGrey border border-surfaceGrey w-[380px] ':
                isDisabled,

              'border-red-400 border-[1px]': (error?.props as {error: string})
                ?.error,
            }
          )}
          placeholder=""
          disabled={isDisabled}
        />
      </div>
      {error && <p className="text-red-500 mt-1">{error}</p>}
    </div>
  );
};

export default CustomPhoneInput;
