import {
  toast,
  Modal,
  ModalContent,
  ModalTitle,
  ModalDescription,
} from '@cashiaApp/web-components';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {useNavigate} from 'react-router-dom';

import {useSignUp, SignUpData} from './api';
import {ReactComponent as Tick} from '../../assets/icons/tick-circle.svg';
import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import FormInput from '../../components/common/FormInput';
import Navbar from '../../components/common/Navbar';
import Spinner from '../../components/common/Spinner';
import RenderErrorMessage from '../../components/common/errorMessage';
import {
  isValidEmail,
  validateKenyanPhoneNumber,
} from '../../utils/helper/validation';
import {cn} from '../../utils/reusablefunctions';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: {
    countryCode: string;
    number: string;
  };
  password: 'P@ssw0rd';
}

interface FormErrors {
  [key: string]: string;
}

type ValidateFunction = (values: FormValues) => FormErrors;

const useFormValidation = (
  initialState: FormValues,
  validate: ValidateFunction
) => {
  const [values, setValues] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const reset = useCallback(() => {
    setValues(initialState);
    setErrors({});
    setIsSubmitting(false);
  }, [initialState]);

  return {
    handleChange,
    reset,
    values,
    setValues,
    errors,
    setErrors,
    isSubmitting,
    validate,
  };
};

const initialState: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: {
    countryCode: '+254',
    number: '',
  },
  password: 'P@ssw0rd',
};

const validate: ValidateFunction = (values) => {
  const errors: FormErrors = {};

  if (values.email && !isValidEmail(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (
    values.phoneNumber?.number?.trim() &&
    !validateKenyanPhoneNumber(values.phoneNumber.number.trim())
  ) {
    errors.phoneNumber = 'Phone number is invalid';
  }
  return errors;
};

const breadcrumbs = [
  {
    name: 'Accounts',
    location: '/accounts',
  },
  {
    name: 'Add New Account',
  },
];

const AddAccount = () => {
  const {signUp} = useSignUp();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [signUpDetails, setSignUpDetails] = useState<SignUpData | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {handleChange, values, setValues, errors, setErrors} =
    useFormValidation(initialState, validate);

  useEffect(() => {
    setErrors(validate(values));
  }, [values, setErrors]);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    const validationErrors = validate(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const signUpData: SignUpData = {
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          password: 'P@ssw0rd',
          phone: {
            country_code: values.phoneNumber.countryCode,
            number: values.phoneNumber.number,
          },
        };

        await signUp(signUpData);
        setSignUpDetails(signUpData);
        setShowModal(true);

        setTimeout(() => {
          setShowModal(false);
          navigate('/accounts');
        }, 3000);
      } catch (error) {
        if (error instanceof Error) {
          if (error.message) {
            if (error.message.includes('email already exists')) {
              toast.error(
                'This email already exists. Please use a different email.'
              );
            } else if (error.message.includes('phone number already exists')) {
              toast.error(
                'This phone number already exists. Please use a different phone number.'
              );
            } else if (error.message.includes('invalid phone format')) {
              toast.error(
                'This phone number has the wrong format. Please use a different phone number.'
              );
            } else {
              toast.error('An unexpected error occurred');
            }
          }
        } else {
          toast.error('An unexpected error occurred');
        }
      }
    }
    setIsSubmitting(false);
  };

  const isButtonDisabled =
    !values.firstName.trim() ||
    !values.lastName.trim() ||
    !values.email.trim() ||
    !values.phoneNumber.number.trim() ||
    Object.keys(errors).length > 0 ||
    isSubmitting;

  return (
    <>
      <Navbar title="Add New Account" omitSearch breadcrumbs={breadcrumbs} />
      <div className="flex h-screen overflow-y-hidden">
        <form className="w-full" onSubmit={handleFormSubmit}>
          <div className="border h-[45%] rounded-lg ml-8 w-[95%] mt-5 border-lightGrey">
            <div className="pl-8 pt-10 pb-3">
              <p className="font-semibold text-xl">Account Information</p>
            </div>
            <div className="flex text-gray">
              <div className="w-[40%] ml-8 mr-3 ">
                <FormInput
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={(val: string) =>
                    handleChange({
                      target: {
                        name: 'firstName',
                        value: val.replaceAll(' ', ''),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                  maxLength={60}
                  error={<RenderErrorMessage error={errors.firstName} />}
                  required
                  className="bg-white w-full "
                />
              </div>
              <div className="w-[40%] ">
                <FormInput
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={(val: string) =>
                    handleChange({
                      target: {
                        name: 'lastName',
                        value: val.replaceAll(' ', ''),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                  maxLength={60}
                  error={<RenderErrorMessage error={errors.lastName} />}
                  required
                  className="bg-white w-full"
                />
              </div>
            </div>
            <div className="flex text-gray">
              <div className="w-[40%] ml-8 mr-3">
                <FormInput
                  label="Email"
                  className="bg-white w-full"
                  type="email"
                  name="Email"
                  value={values.email}
                  error={<RenderErrorMessage error={errors.email} />}
                  onChange={(val: string) =>
                    handleChange({
                      target: {
                        name: 'email',
                        value: val,
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                  maxLength={60}
                  required
                />
              </div>
              <div className="w-[40%]">
                <div className="flex-col">
                  <p className="text-gray font-medium text-sm mb-1">
                    Phone number *
                  </p>
                  <CustomPhoneInput
                    countryCode={values.phoneNumber?.countryCode || ''}
                    phoneNumber={values.phoneNumber?.number || ''}
                    onChange={(countryCode: string, number: string) =>
                      setValues((prevValues) => ({
                        ...prevValues,
                        phoneNumber: {countryCode, number},
                      }))
                    }
                  />

                  <RenderErrorMessage error={errors.phoneNumber} />
                </div>
              </div>
            </div>
            <button
              disabled={isButtonDisabled}
              type="submit"
              className={cn(
                'border rounded-lg p-2 ml-8 font-normal text-base',
                {
                  'bg-lightGrey border-lightGrey text-foggy': isButtonDisabled,
                  'bg-surfacePurple hover:bg-mediumPurple text-white':
                    !isButtonDisabled,
                }
              )}>
              {isSubmitting ? <Spinner className="fill-white" /> : 'Save'}
            </button>
          </div>
        </form>
        <Modal open={showModal}>
          <ModalContent className="w-[33%] pb-10 p-10 border-lightGrey">
            <ModalTitle>
              <div className="flex flex-col justify-center items-center mb-4">
                <Tick className="mb-5 h-10 w-10" />
                <p className="font-semibold text-2xl">Account Created</p>
                <p className="font-semibold text-2xl">Successfully</p>
              </div>
            </ModalTitle>
            <ModalDescription>
              <hr className="mb-4 border-cashia-grey" />
              <div className="justify-between flex mb-2">
                <p className="text-base">First Name:</p>
                <p className="font-semibold text-base">
                  {signUpDetails?.first_name}
                </p>
              </div>
              <div className="justify-between flex mb-2">
                <p className="text-base">Second Name:</p>
                <p className="font-semibold text-base">
                  {signUpDetails?.last_name}
                </p>
              </div>
              <div className="justify-between flex mb-2">
                <p className="text-base">Email:</p>
                <p className="font-semibold text-base">
                  {signUpDetails?.email}
                </p>
              </div>
              <div className="justify-between flex mb-2">
                <p className="text-base">Phone Number:</p>
                <p className="font-semibold text-base">
                  {signUpDetails?.phone.country_code}
                  {signUpDetails?.phone.number}
                </p>
              </div>
            </ModalDescription>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default AddAccount;
