import {clsx, type ClassValue} from 'clsx';
import {twMerge} from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatPhoneNumber = (phone: string) => {
  //this func formats this +254712345678 to this (+254) 712 345 678
  if (phone.at(0) === '+') {
    const code = phone.slice(0, 4);
    const rest = phone.slice(4).match(/.{1,3}/g);
    return `(${code}) ${(rest && rest.join(' ')) || ''}`;
  }
  return phone;
};

export const createHashtag = (name: string) => {
  return name
    .trim()
    .toLowerCase()
    .replace(/&/g, 'and')
    .replace(/\s*\/\s*/g, '-')
    .replace(/\/+/g, '-')
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
};

export function formatFileSize(fileSizeInBytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = 0;
  let fileSize = fileSizeInBytes;
  while (fileSize >= 1024 && index < units.length - 1) {
    fileSize /= 1024;
    index++;
  }
  return `${fileSize.toFixed(0)} ${units[index]}`;
}

export function formatEmuns<T extends string>(name: T) {
  if (name.includes('_')) {
    return name.split('_').join(' ').toLocaleLowerCase();
  }
  return name.toLocaleLowerCase();
}
