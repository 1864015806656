import {Toaster} from '@cashiaApp/web-components';
import {Route, Routes} from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';
import AccountsPage from '../pages/Accounts';
import AccountDetails from '../pages/Accounts/AccountDetails';
import AddAccount from '../pages/Accounts/addAccount';
import BusinessProfiles from '../pages/BusinessProfiles';
import ProfileDetails from '../pages/BusinessProfiles/ProfileDetails';
import Login from '../pages/Login';
import ComingSoon from '../pages/empty';

const AppRoutes = () => {
  return (
    <>
      <Toaster richColors position="bottom-left" />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          <Route index path="accounts" element={<AccountsPage />} />
          <Route index path="accounts/add-account" element={<AddAccount />} />
          <Route path="business-profiles" element={<BusinessProfiles />} />
          <Route path="profile-details/:id" element={<ComingSoon />} />
          <Route
            path="accounts/account-details/:id"
            element={<AccountDetails />}
          />
          <Route path="business-profiles" element={<ComingSoon />} />
          <Route path="users" element={<ComingSoon />} />
          <Route path="payments" element={<ComingSoon />} />
          <Route
            path="business-profiles/details/:id"
            element={<ProfileDetails />}
          />
        </Route>
      </Routes>
    </>
  );
};
export default AppRoutes;
