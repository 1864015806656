const validateKenyanPhoneNumber = (phoneNumber: string) => {
  if (!/^\d+$/.test(phoneNumber)) return false;
  const cleanedNumber = phoneNumber.replace(/\D/g, '');
  const kenyanPhoneRegex = /^(?:\|0)?([17]\d{8})$/;
  const match = cleanedNumber.match(kenyanPhoneRegex);

  return match;
};

export default validateKenyanPhoneNumber;
