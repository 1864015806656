import React from 'react';

import ImgWithText from './ImgWithText';
import {StatusBadge} from './StatusBadge';
import More from '../../assets/icons/more_icon.svg';
import {isArrayAtKey} from '../../utils/helpers';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';

type Props = {
  columns: TableColumnType[];
  data: TableDataType[];
  prettify?: boolean;
  openModal?: (row: TableDataType) => void;
  viewRecord?: (id: string) => void;
  textstyle?: string;
};

const CommonTable = ({
  columns,
  data,
  prettify,
  openModal,
  viewRecord,
  textstyle,
}: Props) => {
  const formatColumnName = (name: TableColumnType) => {
    if (name.includes('_')) return name.split('_').join(' ');
    if (name === 'identificationNumber') return 'National ID';
    return name;
  };

  const shortenTxt = (txt: string, col?: TableColumnType) => {
    if (col === 'date_created' || col === 'date') {
      return txt;
    }
    if (txt?.length > 20) return `${txt.substring(0, 20)}...`;
    return txt;
  };

  return data.length ? (
    <table className="w-full table-auto text-left text-base text-greyscale">
      <thead className="mb-3 border-b border-cashia-grey font-semibold text-darkPurple uppercase leading-5">
        <tr
          className={`${prettify ? 'rounded-t-2xl border-none' : 'border-b'} border-cashia-grey`}>
          {columns.map((column, key) => (
            <th
              className={`${prettify && key === 0 ? 'pl-5' : ''} ${prettify ? 'py-5' : ''} py-3`}
              key={key}>
              <p className="flex gap-1 font-medium">
                {formatColumnName(column)}
              </p>
            </th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody className="font-medium">
        {data.map((row: TableDataType, rowKey) => (
          <tr
            key={rowKey}
            onClick={() => row?.id && viewRecord?.(row.id)}
            className="border-b border-cashia-grey py-5 leading-5 hover:bg-faintGrey cursor-pointer">
            {columns.map((col, colKey) => (
              <td
                key={colKey}
                className={`${prettify && colKey === 0 ? 'pl-5' : ''} py-3`}>
                {col === 'status' || col === 'compliance_status' ? (
                  <StatusBadge status={row[col]?.[0] || ''} type={col} />
                ) : isArrayAtKey(row, col) ? (
                  <ImgWithText
                    variant="square"
                    text={shortenTxt(row[col]?.[0] || '')}
                    subText={shortenTxt(row[col]?.[2] || '')}
                  />
                ) : (
                  <div className={textstyle || ''}>
                    {shortenTxt(row[col] as string, col)}
                  </div>
                )}
              </td>
            ))}
            <td className="pt-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  openModal?.(row);
                }}>
                <img src={More} alt="more" className="cursor-pointer" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <></>
  );
};

export default CommonTable;
